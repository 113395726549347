export const homeObjOne = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'TROCAR DE CONTABILIDADE',
  headline: 'Venha para a Macedo & Muzzio',
  description: 'Fazemos uma revisão do atual cenário contábil e fiscal, e cuidamos de todas as obrigações da sua empresa através de um atendimento personalizado.',
  buttonLabel: 'Preciso de um contador',
  imgStart: false,
  img: '/images/svg-4.svg',
  alt: 'Piggybanck',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjTwo = {
  id: 'services',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'ABERTURA DE EMPRESA',
  headline: 'Abra sua empresa sem sair de casa',
  description: 'Nossa equipe de profissionais experientes ajuda você a se legalizar, oferecendo serviços de abertura e encerramento de empresas, assim como alterações contratuais.',
  buttonLabel: 'Solicite uma proposta',
  imgStart: false,
  img: '/images/svg-5.svg',
  alt: 'car',
  dark: false,
  primary: false,
  darkText: true,
};
export const homeObjThree = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'MICROEMPREENDEDOR',
  headline: 'Alvará para MEI no Rio de Janeiro',
  description: 'Auxiliamos o MEI estabelecido no Município do Rio a fazer o pedido do Alvará e inscrição municipal para a emissão de Nota Carioca.',
  buttonLabel: 'Preciso de um contador',
  imgStart: true,
  img: '/images/MICROEMPREENDEDOR-2.svg',
  alt: 'car',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjFour = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'QUEM SOMOS',
  headline: 'Aliamos tradição e modernidade',
  description: 'Desde 1997, nossa missão é oferecer serviços de excelência na área de contabilidade gerencial, acompanhando nossos clientes de perto.',
  buttonLabel: 'Entre em contato',
  imgStart: false,
  img: '/images/quem-somos.png',
  alt: 'car',
  primary: false,
  darkText: true
};